import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {ProductSlider} from "./components/product-slider/ProductSlider";

const targets = Array.from(document.getElementsByClassName('product-slider'))
    .filter(el => el instanceof HTMLElement)
    .map(el => el as HTMLElement)
    .filter(el => el.dataset['productIds']);

function fetchProductsAndDisplaySlider(targets: HTMLElement[]) {
    targets.forEach((el) => {
        const productIds = el.dataset['productIds']!;
        const productIdArray = productIds.split(",").map(Number);
        const rawLocaleCode = el?.dataset?.['localeCode'];
        const localeCode = getBlLocaleFromString(rawLocaleCode);
        const blackFridayActive: boolean = el?.dataset?.['blackFridayActive'] === 'true';
        const rawOrderId = el?.dataset?.['orderId'];
        const orderId = rawOrderId != null ? parseInt(rawOrderId) : null;
        ReactDOM.render(
                <BundleEntry
                    bundleName="product-slider"
                    locale={localeCode}
                    prefetchTranslations={[
                        'frontend.blackfriday.lottery.missingAmount',
                        'frontend.blackfriday.lottery.turnTheWheel.label',
                        'frontend.blackfriday.lottery.turnTheWheel.button',
                        'frontend.blackfriday.lottery.wheelPopup.heading',
                        'frontend.blackfriday.lottery.successPopup.heading',
                        'frontend.blackfriday.lottery.successPopup.subHeading',
                        'frontend.blackfriday.popup.title',
                        'frontend.blackfriday.popup.later',
                        'frontend.blackfriday.popup.next'
                    ]}>
                    <ProductSlider
                        blackFridayActive={blackFridayActive}
                        orderId={orderId!}
                        productIds={productIdArray} />
                </BundleEntry>
                , el)
        }
    );
}

fetchProductsAndDisplaySlider(targets);